import { render, staticRenderFns } from "./AddCustomerServiceManagement.vue?vue&type=template&id=3433f22a&scoped=true&"
import script from "./AddCustomerServiceManagement.vue?vue&type=script&lang=js&"
export * from "./AddCustomerServiceManagement.vue?vue&type=script&lang=js&"
import style0 from "./AddCustomerServiceManagement.vue?vue&type=style&index=0&id=3433f22a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3433f22a",
  null
  
)

export default component.exports