<template>
<div class="set-service-data">
  <div class="service-data-btn">
    <div>
      <el-button plain @click="deleteRobot">删除</el-button>
      <el-button plain @click="allUnfold(1)">全部展开</el-button>
      <el-button plain @click="allUnfold(2)">全部收起</el-button>
    </div>
    <el-button type="primary" @click="addRobotVisible = true;"><i class="el-icon-plus"></i> 添加机器人</el-button>
    <!--  添加机器人弹框  -->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="添加机器人"
        custom-class="green-dialog2"
        :visible.sync="addRobotVisible"
        @close="addRobotResetForm"
        @opened="openAddRobot"
        width="500px">
      <el-form ref="addRobotForm" :model="addRobotForm" :rules="addRobotRules" label-width="120px">
        <el-form-item label="机器人名称" prop="name">
          <el-input v-model="addRobotForm.name" style="" ref="cursorInput"></el-input>
        </el-form-item>
        <el-form-item label="咨询商品" prop="good_id">
          <el-select v-model="addRobotForm.good_id" placeholder="选择商品">
            <el-option
                v-for="item in stepTwoGoods"
                :key="item.good_id"
                :label="item.good_name"
                :value="item.good_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否已购买" prop="is_have">
          <el-radio-group v-model="addRobotForm.is_have">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="订单编号" prop="order_sn" v-if="addRobotForm.is_have === 1">
          <el-input v-model="addRobotForm.order_sn" style="width: 246px;" placeholder="12位数字"></el-input>
          <el-button plain style="margin-left: 10px" @click="generateOrder">随机生成</el-button>
        </el-form-item>
        <el-form-item label="收件人" prop="recipients" v-if="addRobotForm.is_have === 1">
          <el-input v-model="addRobotForm.recipients"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact" v-if="addRobotForm.is_have === 1">
          <el-input v-model="addRobotForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" prop="address" v-if="addRobotForm.is_have === 1">
          <el-input type="textarea" v-model="addRobotForm.address"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button  @click="addRobotVisible = false">取 消</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="saveAddRobot('addRobotForm')">保存</el-button>
      </div>
    </el-dialog>
    <!--  添加问题弹框  -->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="添加问题"
        custom-class="green-dialog2"
        :visible.sync="addIssueVisible"
        @close="issueResetForm"
        @opened="openAddIssue"
        width="500px">
      <el-form ref="addIssueForm" :model="addIssueForm" :rules="addIssueRules" label-width="120px">
        <el-form-item label="问题类型" prop="type">
          <el-select v-model="addIssueForm.type" placeholder="选择" @change="selectScorePperate">
            <el-option
                v-for="item in issueType"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考点名称" prop="name">
          <el-input v-model="addIssueForm.name" ref="cursorInput"></el-input>
        </el-form-item>
        <el-form-item label="客户问题" prop="question">
          <el-input type="textarea" v-model="addIssueForm.question"></el-input>
        </el-form-item>
        <el-form-item label="标准答案" v-if="addIssueForm.type === 1" prop="answer">
          <el-input type="textarea" v-model="addIssueForm.answer"></el-input>
        </el-form-item>
        <el-form-item label="得分操作" v-if="addIssueForm.type === 1" prop="score_operate">
          <el-input type="textarea" placeholder="用#分割" v-model="addIssueForm.score_operate"></el-input>
        </el-form-item>
        <el-form-item label="得分操作" v-if="addIssueForm.type === 2" prop="score_operate_two">
          <el-select v-model="addIssueForm.score_operate_two" @change="selectScorePperate">
            <el-option
                v-if="item.isShow"
                v-for="item in scoreOperation"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠券" v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 1" prop="coupon_id">
          <el-select v-model="addIssueForm.coupon_id" placeholder="选择优惠券">
            <el-option
                v-for="(item,index) in selectCouponList"
                :key="index"
                :label="item.name"
                :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="直赔原因" v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 2" prop="reason">
          <el-select v-model="addIssueForm.reason" placeholder="请选择直赔原因">
            <el-option
                v-for="item in compensateList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="直赔金额" v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 2" prop="money">
          <el-input-number :controls="false" v-model="addIssueForm.money" :precision="2" :min="0.01"></el-input-number>
        </el-form-item>
        <el-form-item label="商品" v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 3 || addIssueForm.score_operate_two === 4" prop="good_id">
          <el-select v-model="addIssueForm.good_id" placeholder="请选择商品">
            <el-option
                v-for="item in stepTwoGoods"
                :key="item.good_id"
                :label="item.good_name"
                :value="item.good_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 4" prop="couponType_id">
          <el-select v-model="addIssueForm.couponType_id" placeholder="请选择优惠券类型">
            <el-option
                v-for="item in discountsTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠" required v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 4">
          <el-col :span="12">
            <el-form-item prop="price_man">
              满<el-input-number @change="cullSubtract" style="width: 120px;margin: 0 4px" :controls="false" v-model="addIssueForm.price_man" :precision="2" :min="0.01"></el-input-number>元，
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="price_jian">
              减<el-input-number  @change="cullSubtract" style="width: 120px;margin: 0 4px" :controls="false" v-model="addIssueForm.price_jian" :precision="2" :min="0.01"></el-input-number>元
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="有效期" v-if="addIssueForm.type === 2 && addIssueForm.score_operate_two === 4" prop="validity_time">
          <el-select v-model="addIssueForm.validity_time" placeholder="请选择优惠券有效期">
            <el-option :key="1" label="当日23:59:59前使用有效" :value="1"></el-option>
            <el-option :key="2" label="次日23:59:59前使用有效" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="不得分关键词" v-if="addIssueForm.type === 1" prop="no_score_keyword">
          <el-input type="textarea" v-model="addIssueForm.no_score_keyword" placeholder="用#分割"></el-input>
        </el-form-item>
        <el-form-item label="解析" prop="analysis">
          <el-input type="textarea" v-model="addIssueForm.analysis"></el-input>
        </el-form-item>
        <el-form-item label="设置分值" prop="score">
          <el-input-number :controls="false" v-model="addIssueForm.score" :precision="1" :min="1" :max="100"></el-input-number>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button  @click="addIssueVisible = false">取 消</el-button>
        <el-button style="margin-left: 30px" @click="saveAddIssue('addIssueForm')" type="primary">保存</el-button>
      </div>
    </el-dialog>
  </div>
  <div class="service-data-table">
    <div class="data-table-header">
      <div class="table-header-item" :style="flexList[0]">
        <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange" style="margin-right: 6px" v-model="checkAll"></el-checkbox>
        <span>机器人名称</span>
      </div>
      <div class="table-header-item" :style="flexList[1]"><span>咨询商品</span></div>
      <div class="table-header-item" :style="flexList[2]"><span>问题类型</span></div>
      <div class="table-header-item" :style="flexList[3]"><span>客户问题</span></div>
      <div class="table-header-item" :style="flexList[4]"><span>得分操作</span></div>
      <div class="table-header-item"><span>分值（总分{{totalScoreNum}}分）</span></div>
      <div class="table-header-item" :style="flexList[5]"><span>操作</span></div>
    </div>
    <div class="data-table-body">
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
      <div class="table-body-item" v-for="(item,index) in robotList">
        <div class="body-item-top">
          <div class="item-top-item" :style="flexList[0]">
            <i @click="item.isShow = !item.isShow" v-show="item.isShow" class="iconfont">&#xe65f;</i>
            <i @click="item.isShow = !item.isShow" v-show="!item.isShow" class="iconfont">&#xe65d;</i>
            <el-checkbox style="margin-right: 6px" :key="index" :label="index"></el-checkbox>
            <span class="text-overflow">{{item.name}}</span>
          </div>
          <div class="item-top-item" :style="flexList[1]"><span class="text-overflow">{{goodsFormat(item.good_id)}}</span></div>
          <div class="item-top-item" :style="flexList[2]"><span></span></div>
          <div class="item-top-item" :style="flexList[3]"><span class="text-overflow"></span></div>
          <div class="item-top-item" :style="flexList[4]"><span class="text-overflow"></span></div>
          <div class="item-top-item"><span></span></div>
          <div class="item-top-item" :style="flexList[5]">
            <el-link type="success" @click="editRobot(index)">编辑</el-link>
            <el-link type="primary" @click="addIssueBtn(index)">添加问题</el-link>
          </div>
        </div>
        <div class="body-item-bottom" v-for="(questionItem, questionIndex) in item.question" v-show="item.isShow">
          <div class="item-bottom-item" :style="flexList[0]"><span></span></div>
          <div class="item-bottom-item" :style="flexList[1]"><span></span></div>
          <div class="item-bottom-item" :style="flexList[2]"><span>{{questionItem.type === 1 ? '问答题' : '操作题'}}</span></div>
          <div class="item-bottom-item" :style="flexList[3]"><span class="text-overflow">{{questionItem.question}}</span></div>
          <div class="item-bottom-item" v-if="questionItem.type === 1" :style="flexList[4]"><span class="text-overflow">{{questionItem.score_operate}}</span></div>
          <div class="item-bottom-item" v-else :style="flexList[4]"><span class="text-overflow">{{formatOperate(questionItem.score_operate)}}</span></div>
          <div class="item-bottom-item"><span>{{questionItem.score}}</span></div>
          <div class="item-bottom-item" :style="flexList[5]">
            <el-link type="success" @click="questionEdit(index,questionIndex)">编辑</el-link>
            <el-link type="danger" @click="questionDelete(index,questionIndex)">删除</el-link>
          </div>
        </div>
      </div>
      </el-checkbox-group>
    </div>
  </div>
</div>
</template>

<script>
import { kefuCreateSN } from '@/utils/apis'
export default {
  name: "SetServiceData",
  props:['stepTwoGoods', 'editData'],
  data(){
    // 订单编号验证
    var validateOrder = (rule, value, callback) => {
      if(value.length !== 12){
        callback(new Error('订单编号为12位数字'))
      } else {
        let numReg = /^[0-9]*$/;
        let numRe = new RegExp(numReg);
        if(!numRe.test(value)){
          callback(new Error('订单编号格式错误'))
        } else  {
          callback();
        }
      }
    };
    // 手机号验证
    var validateContact = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('联系方式格式有误'))
      } else {
        callback()
      }
    }
    return {
      totalScoreNum:0,
      flexList:[
        {
          flex:0.7
        },
        {
          flex:0.84
        },
        {
          flex:0.8
        },
        {
          flex:1.25
        },
        {
          flex:1.25
        },
        {
          flex:0.8
        },
      ],
      robotList:[],
      show:false,
      checkedCities:[],
      isIndeterminate:false,
      checkAll:false,
      goodsList:[
        {
          name:'测试商品',
          id:1,
        }
      ],
      addRobotVisible:false, // 添加机器人弹框
      addIssueVisible:false, // 添加问题弹框
      addRobotForm:{ // 新增机器人表单
        id:'',
        name:'',
        good_id:'',
        is_have:0,
        order_sn:'',
        recipients:'',
        contact:'',
        address:'',
      },
      addRobotRules:{
        name:[
          { required: true, message: '请输入机器人名称', trigger: 'blur' }
        ],
        good_id:[
          { required: true, message: '请选择咨询商品', trigger: 'change' }
        ],
        is_have:[
          { required: true, message: '请选择是否购买', trigger: 'change' }
        ],
        order_sn:[
          {required: true, message: '请输入订单编号', trigger: 'blur'},
          { validator:validateOrder, trigger: 'blur' }
        ],
        recipients:[
          { required: true, message: '请输入收件人', trigger: 'blur' }
        ],
        contact:[
          {required: true, message: '请输入联系方式', trigger: 'blur'},
          { validator:validateContact,  trigger: 'blur'}
        ],
        address:[
          { required: true, message: '请输入收货地址', trigger: 'blur' }
        ]
      },
      issueType:[
        {
          id:1,
          name:'问答题'
        },
        {
          id:2,
          name:'操作题'
        }
      ],
      scoreOperation:[
        {
          id:1,
          name:'发送商品优惠券',
          isShow:true
        },
        {
          id:2,
          name:'申请直赔',
          isShow:true
        },
        {
          id:3,
          name:'发送商品',
          isShow:true
        },
        {
          id:4,
          name:'发送新建优惠券',
          isShow:true
        },
        {
          id:5,
          name:'发送核对订单',
          isShow:true
        },
      ],
      addIssueIndex:'', // 添加问题索引
      addIssueForm:{ //新增问题表单
        index:'',
        questionIndex:'',
        type:1,
        name:'',
        question:'',
        answer:'',
        score_operate:'',
        score_operate_two:1,
        no_score_keyword:'',
        analysis:'',
        score:void 0,
        coupon_id:'',
        reason:'',
        money:void 0,
        good_id:'',
        couponType_id:'',
        price_man: void 0,
        price_jian: void 0,
        validity_time:'',
      },
      addIssueRules:{
        type:[
          { required: true, message: '请选择问题类型', trigger: 'change' }
        ],
        name:[
          { required: true, message: '请输入考点名称', trigger: 'blur' },
        ],
        question:[
          { required: true, message: '请输入客户问题', trigger: 'blur' },
        ],
        answer:[
          { required: true, message: '请输入标准答案', trigger: 'blur' },
        ],
        score_operate:[
          { required: true, message: '请输入得分操作', trigger: 'blur' },
        ],
        score_operate_two:[
          { required: true, message: '请选择得分操作', trigger: 'change' }
        ],
        no_score_keyword:[
          { required: true, message: '请输入不得分关键词', trigger: 'blur' },
        ],
        analysis:[
          { required: true, message: '请输入解析', trigger: 'blur' },
        ],
        score:[
          { required: true, message: '请输入分数', trigger: 'blur' },
        ],
        coupon_id:[
          { required: true, message: '请选择优惠券', trigger: 'change' }
        ],
        reason:[
          { required: true, message: '请选择直赔原因', trigger: 'change' }
        ],
        money:[
          { required: true, message: '请输入直赔金额', trigger: 'blur' },
        ],
        good_id:[
          { required: true, message: '请选择商品', trigger: 'change' }
        ],
        couponType_id:[
          { required: true, message: '请选择优惠券类型', trigger: 'change' }
        ],
        price_man:[
          { required: true, message: '请输入满元', trigger: 'blur' },
        ],
        price_jian:[
          { required: true, message: '请输入减元', trigger: 'blur' },
        ],
        validity_time:[
          { required: true, message: '请选择优惠券有效期', trigger: 'change' }
        ]
      },
      selectCouponList:[], //优惠券列表
      compensateList:[ //直赔原因
        {
          name:'运费补偿',
          id:1
        },
        {
          name:'收货后少件补偿',
          id:2
        },
        {
          name:'商品质量问题补偿',
          id:3
        },
        {
          name:'商品描述差异问题补偿',
          id:4
        },
        {
          name:'其他类补偿',
          id:5
        },
        {
          name:'活动返现类补偿',
          id:6
        },
        {
          name:'维修退换货瑕疵类补偿',
          id:7
        },
        {
          name:'运转不及时补偿',
          id:8
        },
        {
          name:'未完成送装服务补偿',
          id:9
        },
      ],
      discountsTypeList:[
        {
          name:'商品优惠券',
          id:1
        },
        {
          name:'店铺优惠券',
          id:2
        }
      ],
      cityOptions:[],
    }
  },
  mounted(){
    if(this.$route.query.id){
      let time = setInterval(()=>{
        if(this.editData){
          clearInterval(time);
          this.getEditData()
        }
      },500)
    }
  },
  methods:{
    // 获取编辑数据
    getEditData(){
      let robotData = JSON.parse(JSON.stringify(this.editData.robot_data));
      robotData.forEach(item=>{
        item.isShow = true;
      })
      this.robotList = robotData;
      this.totalScore()
    },
    // 删除机器人
    deleteRobot(){
      if(this.checkedCities.length){
        this.$confirm(`是否删除所选机器人，删除将无法恢复`, '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass:'green-message-box',
          type:'warning'
        }).then(()=>{
          this.checkedCities.sort();
          for(let i = this.checkedCities.length-1; i >= 0; i--){
            this.robotList.splice(this.checkedCities[i],1)
          }
          this.checkedCities = [];
          this.isIndeterminate = false;
          this.cityOptions = []
          this.robotList.map((item,index)=>{
            this.cityOptions.push(index)
          })
          this.totalScore();
        }).catch(()=>{
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        })
      } else {
        this.$message.warning('请选择所要删除的机器人')
      }
    },
    // 全部展开 收起
    allUnfold(num){
      if(num === 1){
        this.robotList.forEach(item=>{
          item.isShow = true;
        })
      } else {
        this.robotList.forEach(item=>{
          item.isShow = false;
        })
      }
    },
    // 总共分数
    totalScore(){
      this.totalScoreNum = 0;
      this.robotList.forEach(item=>{
        item.question.forEach(item2=>{
          this.totalScoreNum += item2.score
        })
      })
    },
    // 格式化操作
    formatOperate(id){
      for(let i = 0; i < this.scoreOperation.length; i++){
        if(this.scoreOperation[i].id === id){
          return this.scoreOperation[i].name
        }
      }
    },
    // 选择得分操作
    selectScorePperate(){
      let num = this.addIssueForm.type;
      let num2 = this.addIssueForm.score_operate_two;
      this.$nextTick(()=>{
        this.$refs.addIssueForm.clearValidate();
      })
      this.addIssueForm.type = num;
      this.addIssueForm.score_operate_two = num2;
    },
    // 商品id格式化
    goodsFormat(id){
      let str = '';
      if(this.stepTwoGoods){
        for(let i = 0; i < this.stepTwoGoods.length; i++){
          if(this.stepTwoGoods[i].good_id === id){
            str = this.stepTwoGoods[i].good_name;
            return str;
          }
        }
      } else {
        return str;
      }
    },
    // 编辑机器人
    editRobot(index){
      this.addRobotForm.id = index;
      this.addRobotVisible = true;
    },
    // 编辑问题
    questionEdit(index,questionIndex){
      this.stepTwoGoods.forEach(item=>{
        if(item.good_id === this.robotList[index].good_id){
          this.selectCouponList = item.coupon
        }
      })
      this.addIssueForm.index = index;
      this.addIssueForm.questionIndex = questionIndex;
      this.addIssueForm.name = this.robotList[index].question[questionIndex].name;
      this.addIssueForm.question = this.robotList[index].question[questionIndex].question;
      this.addIssueForm.type = this.robotList[index].question[questionIndex].type;
      this.addIssueForm.analysis = this.robotList[index].question[questionIndex].analysis;
      this.addIssueForm.score = this.robotList[index].question[questionIndex].score;
      if(this.robotList[index].question[questionIndex].type === 1){
        this.addIssueForm.answer = this.robotList[index].question[questionIndex].answer;
        this.addIssueForm.score_operate = this.robotList[index].question[questionIndex].score_operate;;
        this.addIssueForm.no_score_keyword = this.robotList[index].question[questionIndex].no_score_keyword;;
      } else {
        this.addIssueForm.score_operate_two = this.robotList[index].question[questionIndex].score_operate;
        if(this.addIssueForm.score_operate_two === 1){
          this.addIssueForm.coupon_id = this.robotList[index].question[questionIndex].coupon_id
        } else if(this.addIssueForm.score_operate_two === 2){
          this.addIssueForm.reason = this.robotList[index].question[questionIndex].reason;
          this.addIssueForm.money = this.robotList[index].question[questionIndex].money;
        } else if(this.addIssueForm.score_operate_two === 3){
          this.addIssueForm.good_id = this.robotList[index].question[questionIndex].good_id
        } else if(this.addIssueForm.score_operate_two === 4){
          this.addIssueForm.good_id = this.robotList[index].question[questionIndex].good_id;
          this.addIssueForm.couponType_id = this.robotList[index].question[questionIndex].coupon_id;
          this.addIssueForm.price_man = this.robotList[index].question[questionIndex].price_man;
          this.addIssueForm.price_jian = this.robotList[index].question[questionIndex].price_jian;
          this.addIssueForm.validity_time = this.robotList[index].question[questionIndex].validity_time;
        }
      }
      if(this.robotList[index].is_have === 0){
        this.scoreOperation[4].isShow = false;
      } else {
        this.scoreOperation[4].isShow = true;
      }
      this.addIssueVisible = true;
    },
    // 满减限制
    cullSubtract(){
      if(this.addIssueForm.price_man && this.addIssueForm.price_jian){
        if(this.addIssueForm.price_jian > this.addIssueForm.price_man){
          this.$nextTick(()=>{
            this.$set(this.addIssueForm,'price_jian', void 0);
            this.$message('满减优惠所减价格不能大于已满价格');
          })
        }
      }
    },
    // 删除问题
    questionDelete(index,questionIndex){
      this.$confirm(`是否删除该问题，删除将无法恢复`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        this.robotList[index].question.splice(questionIndex,1);
        this.totalScore();
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    // 新增问题按钮
    addIssueBtn(index){
      this.addIssueIndex = index;
      if(this.robotList[index].is_have === 0){
        this.scoreOperation[4].isShow = false
      } else {
        this.scoreOperation[4].isShow = true
      }
      this.stepTwoGoods.forEach(item=>{
        if(item.good_id === this.robotList[index].good_id){
          this.selectCouponList = item.coupon
        }
      })
      this.addIssueVisible = true;
    },
    // 保存新增机器人
    saveAddRobot(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          if(this.addRobotForm.is_have === 0){
            let obj = {
              name:this.addRobotForm.name,
              good_id:this.addRobotForm.good_id,
              is_have:0,
              question:[],
              isShow:true,
            }
            if(this.addRobotForm.id !== ''){
              let index = this.addRobotForm.id;
              obj.isShow =  this.robotList[index].isShow;
              obj.question =  this.robotList[index].question;
              this.robotList[index] = obj
            } else {
              this.robotList.push(obj)
            }
          } else {
            let obj = {
              name:this.addRobotForm.name,
              good_id:this.addRobotForm.good_id,
              is_have:1,
              order_sn:this.addRobotForm.order_sn,
              recipients:this.addRobotForm.recipients,
              contact:this.addRobotForm.contact,
              address:this.addRobotForm.address,
              question:[],
              isShow:true,
            }
            if(this.addRobotForm.id !== ''){
              let index = this.addRobotForm.id;
              obj.isShow =  this.robotList[index].isShow;
              obj.question =  this.robotList[index].question;
              this.robotList[index] = obj
            } else {
              this.robotList.push(obj);
            }
          }
          this.cityOptions = [];
          this.robotList.forEach((item,index)=>{
            this.cityOptions.push(index)
          })
          this.addRobotVisible = false;
          if(this.addRobotForm.id !== ''){
            this.$message.success('修改成功！')
          } else {
            this.$message.success('添加成功！')
          }
        } else {
          return false;
        }
      })
    },
    // 保存新增问题
    saveAddIssue(formName){
      console.log(this.robotList[this.addIssueIndex])
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let obj = {
            name:this.addIssueForm.name,
            question:this.addIssueForm.question,
            type:this.addIssueForm.type,
            analysis : this.addIssueForm.analysis,
            score:this.addIssueForm.score,
          }
          if(this.addIssueForm.type === 1){
            obj.answer = this.addIssueForm.answer;
            obj.score_operate = this.addIssueForm.score_operate;
            obj.no_score_keyword = this.addIssueForm.no_score_keyword;
          } else {
            obj.score_operate = this.addIssueForm.score_operate_two;
            if(this.addIssueForm.score_operate_two === 1){
              obj.coupon_id = this.addIssueForm.coupon_id;
            } else if(this.addIssueForm.score_operate_two === 2){
              obj.reason = this.addIssueForm.reason;
              obj.money = this.addIssueForm.money;
            } else if (this.addIssueForm.score_operate_two === 3){
              obj.good_id = this.addIssueForm.good_id
            } else if (this.addIssueForm.score_operate_two === 4){
              obj.good_id = this.addIssueForm.good_id;
              obj.coupon_id = this.addIssueForm.couponType_id;
              obj.price_man = this.addIssueForm.price_man;
              obj.price_jian = this.addIssueForm.price_jian;
              obj.validity_time = this.addIssueForm.validity_time;
            }
          }
          if(this.addIssueForm.index !== '' && this.addIssueForm.questionIndex !== ''){
            this.robotList[this.addIssueForm.index].question[this.addIssueForm.questionIndex] = obj;
            this.$message.success('修改成功');
            this.totalScore();
          } else {
            this.robotList[this.addIssueIndex].question.push(obj);
            this.$message.success('添加成功');
            this.totalScore();
          }

          this.addIssueVisible = false
        } else {
          return false
        }
      })
    },
    // 打开添加机器人弹框
    openAddRobot(){
      if(this.addRobotForm.id !== ''){
        let index = this.addRobotForm.id;
        if(this.robotList[index].is_have === 0){
          this.addRobotForm.is_have = 0;
          this.addRobotForm.name = this.robotList[index].name;
          this.addRobotForm.good_id = this.robotList[index].good_id;
        } else {
          this.addRobotForm.is_have = 1;
          this.addRobotForm.name = this.robotList[index].name;
          this.addRobotForm.good_id = this.robotList[index].good_id;
          this.addRobotForm.order_sn = this.robotList[index].order_sn;
          this.addRobotForm.recipients = this.robotList[index].recipients;
          this.addRobotForm.contact = this.robotList[index].contact;
          this.addRobotForm.address = this.robotList[index].address;
        }
      }
      // this.$refs.cursorInput.focus();
    },
    // 打开添加问题弹框
    openAddIssue(){

    },
    // 关闭添加机器人弹框
    addRobotResetForm(){
      this.$refs.addRobotForm.resetFields()
      this.addRobotForm.is_have = 0;
      this.addRobotForm.name = '';
      this.addRobotForm.good_id = '';
      this.addRobotForm.order_sn = '';
      this.addRobotForm.recipients = '';
      this.addRobotForm.contact = '';
      this.addRobotForm.address = '';
      this.addRobotForm.id = '';
    },
    // 关闭添加问题弹框
    issueResetForm(){
      this.$refs.addIssueForm.clearValidate();
      this.addIssueForm = {
        index:'',
        questionIndex:'',
        type:1,
        name:'',
        question:'',
        answer:'',
        score_operate:'',
        score_operate_two:1,
        no_score_keyword:'',
        analysis:'',
        score:void 0,
        coupon_id:'',
        reason:'',
        money:void 0,
        good_id:'',
        couponType_id:'',
        price_man: void 0,
        price_jian: void 0,
        validity_time:'',
      }
      this.addIssueIndex = '';
    },
    // 生成订单
    generateOrder(){
      kefuCreateSN().then((res)=>{
        this.$message.success(res.msg);
        this.addRobotForm.order_sn = res.data;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    confirmForm(){
      if(this.robotList.length){
        for(let i = 0; i < this.robotList.length; i++){
          if(this.robotList[i].question.length < 1){
            this.$message.warning('每个机器人最少添加一个问题');
            return
          }
        }
        let robot = JSON.parse(JSON.stringify(this.robotList));
        for(let i = 0; i < robot.length; i++){
          delete robot[i].isShow
        }
        this.$emit('stepActiveAdd',robot)
      } else {
        this.$message.warning('请添加最少一个机器人')
      }
    },
    //全选
    handleCheckAllChange(val){
      this.checkedCities = val ? this.cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value){
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cityOptions.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cityOptions.length;
    }
  }
}
</script>

<style scoped lang="scss">
.set-service-data{
  margin-top: 20px;
  .service-data-btn{
    display: flex;
    justify-content: space-between;
  }
  .service-data-table{
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .data-table-header{
      padding: 0 20px 0 20px;
      display: flex;
      background: #EEEEEE;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      span{
        color: #666666;
        font-size: 16px;
      }
      .table-header-item{
        flex: 1;
        width: 1%;
        box-sizing: border-box;
        &:nth-child(2){
          span{
            margin-left: 16px;
          }
        }
        &:nth-child(3),&:nth-child(6),&:last-of-type{
          text-align: center;
        }
        &:nth-child(5){
          span{
            margin-left: 40px;
          }
        }
      }
    }
    .data-table-body{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .table-body-item{
        display: flex;
        flex-direction: column;
        .body-item-top, .body-item-bottom{
          padding: 0 20px 0 20px;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          border-bottom: 1px solid #EEEEEE;
          .item-top-item, .item-bottom-item{
            width: 1%;
            flex: 1;
            height: 70px;
            display: flex;
            align-items: center;
            &:nth-child(2){
              span{
                margin-left: 16px;
              }
            }
            i{
              line-height: 1;
              cursor: pointer;
              font-size: 18px;
              color: #7D7D7D;
              margin-right: 4px;
            }
            span{
              //flex: 1;
              font-size: 16px;
              color: #333;
            }
            &:nth-child(3),&:nth-child(6),&:last-of-type{
              justify-content: center;
            }
            &:nth-child(5){
              span{
                margin-left: 40px;
              }
            }
          }
        }
      }
    }
  }
}
::v-deep.el-checkbox{
  .el-checkbox__label{
    display: none;
  }
}
::v-deep .green-dialog2{
  .el-dialog__header{
    padding: 16px 24px;
    background: #2DC079;
    span,i{
      font-size: 16px;
      line-height: 1;
      color: #fff;
    }
  }
  .el-dialog__body{
    padding:22px 24px 22px 0px;
    // 带表格的
    .el-form-item{
      margin-bottom: 24px;
    }
    .el-form-item__label{
      font-size: 16px;
      color: #666666;
      //margin-bottom: 10px;
    }
    .dialog-footer{
      margin-top: 30px;
      text-align: center;
    }
  }
}
</style>