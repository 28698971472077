<template>
<el-scrollbar class="add-customer-service">
  <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
    <el-breadcrumb-item :to="{ path: '/' }">实操题</el-breadcrumb-item>
    <el-breadcrumb-item>添加客服数据</el-breadcrumb-item>
  </el-breadcrumb>
  <div class="steps-wrapper">
    <div class="step-item" :class="{current: [1,2,3,4].includes(stepActive)}">
      <div class="step-line"></div>
      <div class="step-text">1</div>
      <div class="step-title">创建名称</div>
    </div>
    <div class="step-item" :class="{current: [2,3,4].includes(stepActive)}">
      <div class="step-line"></div>
      <div class="step-text">2</div>
      <div class="step-title">设置商品</div>
    </div>
    <div class="step-item" :class="{current: [3,4].includes(stepActive)}">
      <div class="step-line"></div>
      <div class="step-text">3</div>
      <div class="step-title">设置客服数据</div>
    </div>
    <div class="step-item" :class="{current: [4].includes(stepActive)}">
      <div class="step-line"></div>
      <div class="step-text">4</div>
      <div class="step-title">创建成功</div>
    </div>
  </div>
  <div class="customer-service-form">
    <CreationServiceName @stepActiveAdd="stepActiveAdd" ref="CreationServiceName" :editData="editData" v-show="stepActive === 1" />
    <SetServiceGoods @stepActiveAdd="stepActiveAdd" ref="SetServiceGoods" :editData="editData" v-show="stepActive === 2" />
    <SetServiceData rel="SetServiceData" :editData="editData" @stepActiveAdd = stepActiveAdd :stepTwoGoods="stepTwoGoods" ref="SetServiceData" v-show="stepActive === 3" />
    <div v-if="stepActive === 4" class="creating-success">
      <div class="success-img">
        <img src="./../../../assets/images/admin/success.png" alt="">
      </div>
      <span>{{ editId ? '保存成功' : '创建成功' }}</span>
      <el-button @click="toList" type="primary">返回列表</el-button>
    </div>
  </div>
  <div class="dialog-footer">
    <el-button v-if="stepActive === 1" @click="toBack">取消</el-button>
    <el-button @click="lastStep" v-if="stepActive !== 1 && stepActive !==4">上一步</el-button>
    <el-button style="margin-left: 30px" type="primary" v-if="stepActive !==4" @click="nextStep">下一步</el-button>
  </div>
</el-scrollbar>
</template>

<script>
import CreationServiceName from '@/components/admin/practicalExercises/ServiceManagementAdd/CreationServiceName.vue'
import SetServiceGoods from '@/components/admin/practicalExercises/ServiceManagementAdd/SetServiceGoods.vue'
import SetServiceData from '@/components/admin/practicalExercises/ServiceManagementAdd/SetServiceData.vue'
import {keFuSaveKeFuData, keFuGetKeFuData} from '@/utils/apis'
export default {
  name: "AddCustomerServiceManagement",
  components:{
    CreationServiceName,
    SetServiceGoods,
    SetServiceData
  },
  data(){
    return {
      //步骤
      stepActive: 1,
      // 第一步的数据
      stepOneData:null,
      // 第二步的数据
      stepTwoData:null,
      stepTwoGoods:null, // 第二步数据添加name第三步要用
      // 第三步的数据
      stepThreeData:null,
      editId:this.$route.query.id || '',
      editData:null,
    }
  },
  mounted() {
    if(this.editId){
      this.getEditData()
    }
  },
  methods:{
    getEditData(){
      let params = {
        kefu_id:this.editId
      }
      keFuGetKeFuData(params).then((res)=>{
        this.editData = res.data;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    toBack(){
      this.$router.go(-1)
    },
    lastStep(){
      this.stepActive--
    },
    toList(){
      this.$router.push('/practice/customerServiceManagement')
    },
    // 获取每步的数据
    stepActiveAdd(data){
      if(this.stepActive === 1){
        this.stepOneData = data;
        this.stepActive++;
        let category_id = this.stepOneData.category_id[this.stepOneData.category_id.length - 1];
        this.$refs.SetServiceGoods.getAdminGoodListGood(category_id)
      } else if(this.stepActive === 2){
        this.stepTwoGoods = data;
        let stepTwoData = JSON.parse(JSON.stringify(data));
        stepTwoData.forEach(item=>{
          delete item.good_name
        })
        this.stepTwoData = stepTwoData;
        this.stepActive++;
      } else if(this.stepActive === 3){
        this.stepThreeData = data;
        let good_data = JSON.stringify(this.stepTwoData);
        let robot_data = JSON.stringify(data);
        let category_id = this.stepOneData.category_id[this.stepOneData.category_id.length - 1];
        let formData = new FormData();
        formData.append('name',this.stepOneData.name);
        formData.append('category_id',category_id);
        formData.append('repeat_ask_num',this.stepOneData.repeat_ask_num);
        formData.append('wait_time',this.stepOneData.wait_time);
        formData.append('visit_interval',this.stepOneData.visit_interval);
        formData.append('response_time',this.stepOneData.response_time);
        formData.append('score_prop',this.stepOneData.score_prop);
        formData.append('scene',this.stepOneData.scene);
        formData.append('banned_word',this.stepOneData.banned_word);
        formData.append('good_data', good_data);
        formData.append('robot_data', robot_data);
        if(this.editId){
          formData.append('kefu_id', this.editId);
        }
        keFuSaveKeFuData(formData).then((res)=>{
          this.$message.success(res.msg);
          this.stepActive++
        }).catch((err)=>{
          console.log('err', err)
        })
      }
      //
    },
    nextStep(){
      if(this.stepActive === 1){
        this.$refs.CreationServiceName.confirmForm()
      } else if(this.stepActive === 2){
        this.$refs.SetServiceGoods.confirmForm()
      } else if(this.stepActive === 3){
        this.$refs.SetServiceData.confirmForm()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.add-customer-service{
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 0px 20px;
    }
  }
  .dialog-footer{
    text-align: center;
    margin: 100px 0 50px;
  }
  .creating-success{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    .success-img{
      width: 240px;
      height: 240px;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
    span{
      color: #333333;
      font-size: 24px;
      line-height: 1;
      margin: 32px 0 36px;
    }
  }
}

</style>